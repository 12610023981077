import React from "react";
import "./Loader.scss";
import ReactDOM from "react-dom";

const LoadingAnimation = () => {
  return (
    <div className="spinner">
      <span className="ball-1"></span>
      <span className="ball-2"></span>
      <span className="ball-3"></span>
      <span className="ball-4"></span>
      <span className="ball-5"></span>
      <span className="ball-6"></span>
      <span className="ball-7"></span>
      <span className="ball-8"></span>
    </div>
  );
};

const Loader = () => {
  return ReactDOM.createPortal(
    <div className="wrapper">
      <div className="loader">
        <LoadingAnimation />
      </div>
    </div>,
    document.getElementById("loader")
  );
};

export const Spinner = () => {
  return (
    <div className="--center-all">
      <LoadingAnimation />
    </div>
  );
};

export default Loader;
