import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { goHome } from "../../utils";
import { FaLink } from "react-icons/fa";

const Header = () => {
  return (
    <header>
      <div className=" container header">
        <div className="logo --cursor" onClick={goHome}>
          <FaLink size={30} color="#fff" style={{ cursor: "pointer" }} />
          <p className="logo-text">
            Link<span>ito</span>
          </p>
        </div>
        <nav>
          <ul className="nav">
            <li>
              <p className="--text-light --cursor" onClick={goHome}>
                <b>Linkito.app</b>
              </p>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
