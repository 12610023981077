import React, { useEffect, useState } from "react";
import "./Home.scss";

const Home = () => {
  const [count, setCount] = useState(5);

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.replace("https://linkito.app/");
    }, 5000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (count !== 0) {
        setCount((prevCount) => prevCount - 1);
      }
    }, 1000);

    return () => clearTimeout(countdown);
  }, [count]);

  return (
    <section>
      <div className="container home">
        <h2 className="--text-center">Redirecting to Linkito.app in...</h2>
        <h2 className="--text-center">{count}</h2>
      </div>
    </section>
  );
};

export default Home;
