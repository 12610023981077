import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { GiFishingHook } from "react-icons/gi";
import { FaHandPointDown } from "react-icons/fa";
import Card from "../../components/card/Card";
import "./AdPage.scss";
import { Spinner } from "../../components/loader/Loader";
import Countdown from "../../components/countdown/Countdown";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const API_URL = `${BACKEND_URL}/api/`;

const AdPage = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAd, setLoadingAd] = useState(false);
  const [isError, setIsError] = useState(false);
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [page, setPage] = useState(null);
  const [selectedAd, setSelectedAd] = useState(null);
  const [count, setCount] = useState(5);
  const [defaultAd, setDefaultAd] = useState(true);
  const [cdTime, setCdTime] = useState("");
  const [cdDate, setCdDate] = useState("");

  //   Get Page
  const getPage = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(API_URL + "page/" + id);
      //   console.log(response.data);
      setPage(response.data);
      setIsLoading(false);
      return response.data;
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage(msg);
      setIsLoading(false);
      toast.error(msg);
    }
  };

  useEffect(() => {
    getPage();
  }, []);

  // Check Default Ad
  const getDefaultAd = async () => {
    setLoadingAd(true);
    setDefaultAd(true);
    try {
      const response = await axios.get(API_URL + "prod/getDefaultProd/" + id);
      if (response.data === "No Default Ad") {
        // console.log(response.data);
        setDefaultAd(false);
      }
      if (typeof response.data === "object") {
        setSelectedAd(response.data);
        if (response.data?.countdownDate && response.data?.countdownTime) {
          setCdTime(response.data?.countdownTime);
          setCdDate(response.data?.countdownDate);
        }
      }
      setLoadingAd(false);
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage2(msg);
      setLoadingAd(false);
      setDefaultAd(false);
      toast.error(msg);
    }
  };

  useEffect(() => {
    if (page !== null) {
      getDefaultAd();
    }
  }, [page]);

  //   Get Ad
  const getAd = async (ads) => {
    setIsLoading(true);
    try {
      const response = await axios.get(API_URL + "prod/" + ads);
      // console.log(response.data);
      setSelectedAd(response.data);
      if (response.data?.countdownDate && response.data?.countdownTime) {
        setCdTime(response.data?.countdownTime);
        setCdDate(response.data?.countdownDate);
      }
      setIsLoading(false);
    } catch (error) {
      const msg =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      setMessage2(msg);
      setIsLoading(false);
      toast.error(msg);
    }
  };

  useEffect(() => {
    if (page !== null && page?.ads && !defaultAd) {
      getAd(page.ads);
    }
  }, [page, defaultAd]);

  const countAdClicks = async (ads) => {
    try {
      await axios.patch(API_URL + "prod/countProdClicks/" + ads);
      await axios.patch(API_URL + "page/countPageClicks/" + id);
    } catch (error) {
      console.log(error);
    }
  };

  const openAd = async (ads) => {
    if (page !== null && page?.ads) {
      countAdClicks(page.ads);
    }
    window.open(ads);
  };

  useEffect(() => {
    const countdown = setInterval(() => {
      if (count !== 0) {
        setCount((prevCount) => prevCount - 1);
      }
    }, 1000);

    return () => clearTimeout(countdown);
  }, [count]);

  return (
    <section>
      <div className="container">
        {isLoading && <Spinner />}
        {page?.message && <h3 className="--text-center">Invalid page id...</h3>}
        {page !== null ? (
          <>
            {page?.link && (
              <h3 className="--text-center">
                Your <FaHandPointDown color="orangered" />{" "}
                {page.link2 ? "Links" : "Link"}
              </h3>
            )}
            <div className=" --flex-center --mb2">
              {page?.linkText && count !== 0 && (
                <p>
                  <b>
                    Getting link in{" "}
                    <span className="--color-danger">{count}</span>
                  </b>
                </p>
              )}
              {page?.linkText && count < 1 && (
                <button
                  onClick={() => window.open(page?.link)}
                  className="--btn --btn-primary --btn-lg"
                >
                  {page?.linkText}
                </button>
              )}
              &nbsp; &nbsp; &nbsp;
              {page?.linkText2 && count < 1 && (
                <button
                  onClick={() => window.open(page?.link2)}
                  className="--btn --btn-danger --btn-lg"
                >
                  {page?.linkText2}
                </button>
              )}
            </div>
          </>
        ) : (
          <p className="--text-center --mb2">
            Something went wrong!!! {message}
          </p>
        )}
      </div>

      <div className="--text-center">
        <GiFishingHook size={35} color="purple" />
      </div>

      <div className="ad --flex-center --dir-column">
        {page !== null && selectedAd !== null && (
          <Countdown cdTime={cdTime} cdDate={cdDate} />
        )}
        {page !== null && selectedAd !== null ? (
          <Card cardClass={"ad-details"}>
            <div className="image-preview">
              <img src={selectedAd?.image} alt="adImage" />

              {selectedAd?.price > 0 && (
                <span className="price">${selectedAd?.price}</span>
              )}

              {selectedAd?.salePrice > 0 && (
                <span className="salePrice">${selectedAd?.salePrice}</span>
              )}

              {selectedAd?.coupon && (
                <span className="coupon">COUPON: {selectedAd?.coupon}</span>
              )}
            </div>

            <h3 className="--mt">{selectedAd?.heading}</h3>
            <p className="--mb">{selectedAd?.desc}</p>
            {selectedAd?.linkText && (
              <button
                className="--btn --btn-primary --btn-lg --btn-block"
                onClick={() => openAd(selectedAd?.link)}
              >
                {selectedAd?.linkText}
              </button>
            )}
          </Card>
        ) : (
          <p>
            <b>No Offer Found... {message2}</b>
          </p>
        )}
      </div>
    </section>
  );
};

export default AdPage;
