import React from "react";
import { goHome } from "../../utils";

const Footer = () => {
  return (
    <div className="--width-100 --p">
      <hr />
      <p onClick={goHome} className="--cursor --text-center --p">
        &copy; Linkito App
      </p>
    </div>
  );
};

export default Footer;
