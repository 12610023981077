import React, { useState, useEffect } from "react";
import "./Countdown.scss";

const Count = ({ count, time }) => {
  return (
    <div className="card-flip">
      <span className="card-number">
        <span className="card-number-top" />
        {count}
      </span>
      <p>
        <b>{time}</b>{" "}
      </p>
    </div>
  );
};

const Countdown = ({ cdTime, cdDate }) => {
  // "2023-03-20 05:05"
  const [targetDate, setTargetDate] = useState(new Date("0000-00-00 00:00"));
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  // console.log(targetDate);
  useEffect(() => {
    if (cdTime !== "" && cdDate !== "") {
      const dateTime = cdDate + " " + cdTime;
      setTargetDate(new Date(dateTime));
    }
  }, [cdTime, cdDate]);

  useEffect(() => {
    if (
      isNegative(timeLeft.days) &&
      isNegative(timeLeft.hours) &&
      isNegative(timeLeft.hours) &&
      isNegative(timeLeft.seconds)
    ) {
      return;
    }
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  function calculateTimeLeft() {
    // console.log(targetDate);
    const now = new Date();
    const timeDifference = targetDate.getTime() - now.getTime();

    let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    let hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);
    // setTimeDiff(timeDifference);
    return { days, hours, minutes, seconds };
  }

  function isNegative(number) {
    if (number < 0) {
      return true;
    } else {
      return false;
    }
  }

  if (
    isNegative(timeLeft.days) &&
    isNegative(timeLeft.hours) &&
    isNegative(timeLeft.hours) &&
    isNegative(timeLeft.seconds)
  ) {
    return null;
  }

  if (cdDate === "" || cdTime === "") {
    return null;
  }

  return (
    <>
      <h4>Discount Offer ends in:</h4>
      <div className="countdown">
        <>
          <Count time="Days" count={timeLeft.days} />
          <Count time="Hours" count={timeLeft.hours} />
          <Count time="Minutes" count={timeLeft.minutes} />
          <Count time="Seconds" count={timeLeft.seconds} />
        </>
      </div>
    </>
  );
};

export default Countdown;
